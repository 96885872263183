export const environment = {
  apiUrl: 'https://api.servispozitif.com/api/',
  kurumid : 20138,
  production: true,
  isontalepgoster : true,
  istalepgoster : false,
  logo : "https://app.servispozitif.com/Content/images/logo_e9fbc36d-1595-449e-a457-0ee309395250.png",
  footer1:"TEKNOPRO - www.teknoproteknoloji.com - info@teknoproteknoloji.com",
  footer2:"Destek: +0532 792 00 77"
};
